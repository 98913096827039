import logo from "./logo.svg";
import "./App.css";

function myClick() {
  return alert("Hello!");
}

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <span>
          <strong style={{ color: "pink" }}>Random Text</strong>
        </span>
        <button style={{ marginTop: "2em" }} type="button" onClick={myClick}>
          Bob Button
        </button>
      </header>
    </div>
  );
}

export default App;
